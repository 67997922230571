import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-851a31c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ml-2 text-xl-h5 text-md-subtitle-1 text-sm-caption text-caption font-weight-bold pa-0 content-center"
}
const _hoisted_2 = {
  class: "",
  style: {"font-weight":"bold","font-size":"16px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_toolbar = _resolveComponent("v-toolbar")

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    color: $options.colorStatusApontamento,
    class: "toolbar-realtime mb-md-5 mb-sm-3 mb-xs-3 mt-1",
    height: "40",
    id: "v-toolbar_production"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "pl-0 d-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "1",
            class: "pr-0 pt-0 pb-0"
          }, {
            default: _withCtx(() => [
              ($options.verify_status_sensor)
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 0,
                    color: $options.status_color,
                    style: {"width":"100%","height":"100%"},
                    class: "d-flex align-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        size: $data.display != 'xs' ? '35' : '15'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-lan-connect")
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["size"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["color"]))
                : (_openBlock(), _createBlock(_component_v_card, {
                    key: 1,
                    color: '#37474F',
                    style: {"width":"100%","height":"100%"},
                    class: "d-flex align-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        size: $data.display != 'xs' ? '35' : '15'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-lan-disconnect")
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["size"])
                    ]),
                    _: 1 /* STABLE */
                  }))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_col, {
            cols: "11",
            class: "pl-0 d-flex align-center"
          }, {
            default: _withCtx(() => [
              ($options.appStore.apontamento_atual.name)
                ? (_openBlock(), _createElementBlock("small", _hoisted_1, " TEMPO REAL: " + _toDisplayString($data.tempExecucao) + " | ID : " + _toDisplayString($options.appStore.maquina_sel.id) + " | ", 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($options.appStore.maquina_sel && $options.appStore.maquina_sel.nome)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    id: "btn-maquina",
                    variant: "tonal",
                    class: "px-0",
                    onClick: $options.ShowMaquinas,
                    color: $options.appStore.apontamento_atual.tipo === 'Produção' ? '#1B5E20' : '#E53935',
                    style: {"font-weight":"bold","font-size":"16px","color":"white"},
                    size: "small",
                    width: "35%"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "py-0 text-md-subtitle-1 text-sm-caption font-weight-medium text-caption reticencias" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($options.appStore.maquina_sel.nome), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick", "color"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("small", _hoisted_2, " | " + _toDisplayString($options.appStore.maquina_sel.leitor), 1 /* TEXT */),
              ($options.appStore.apontamento_atual.op)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    class: "px-0",
                    style: {"font-weight":"bold","font-size":"16px","color":"white"},
                    onClick: $options.ShowDetalheOS,
                    variant: "tonal",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { icon: "mdi-folder-open" }),
                      _createTextVNode(" " + _toDisplayString($data.display != 'sm' && $data.display != 'xs' ? 'Desenhos': ''), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color"]))
}