import { defineStore } from "pinia";

declare let frappe: any;


interface recurso {
    leitor: String,
    name: String,
    nome: String,
    state_sensor: String,
    desc_motivo_parada: String,
    dt_inicio: String,
    nome_operador: String,
    observacao: String,
    ordem_de_producao: String,
    tipo: String,
    turno: String,
    apontamento: String
}

interface dashboard {
    producao: recurso[],
    parada: recurso[],
    manutencao: recurso[],
    setup: recurso[],
    offline: recurso[],
}
export const useAppFabricaAtual = defineStore({
    id: "appFabricaAtual_iot",
    state: (): {
        resourceRecursos: dashboard,
    } => ({
        resourceRecursos: {
            producao: [],
            parada: [],
            manutencao: [],
            setup: [],
            offline: [],
        },
    }),

    getters: {},

    actions: {
        async listar_recursos_abertos() {
            console.log("listar_recursos_abertos");
            await frappe.call("nxlite.nx_producao.page.apontamento_iot.controller.lista_recursos_abertos").then((response: any) => {
                this.resourceRecursos = response.message;
                console.log(this.resourceRecursos);
            });
        }
    }
});
