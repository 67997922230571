<template>
        <v-card height="100%">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" :filtroTurnos="false" :filtroData="false"/>
            <v-row class="mt-2" align="center">
                <v-col cols="4" sm="4" md="4" class="pa-0 pt-2 pl-1">
                    <v-card color="#2E7D32" dark :class="classCard"  rounded>
                        <span class="truncate-text">TRABALHANDO</span>
                        <span class="">{{ appFabricaAtual.resourceRecursos.producao.length }}</span>
                    </v-card>
                </v-col>

                <!-- Coluna Parado -->
                <v-col cols="4" sm="4" md="4" class="pa-0 pt-2 px-1">
                    <v-card color="#D50000" :class="classCard"  rounded>
                        <span class="">PARADO</span>
                        <span class="">{{ appFabricaAtual.resourceRecursos.parada.length }}</span>
                    </v-card>
                </v-col> 

                <!-- Coluna Setup, Manutenção, Desligada -->
                <v-col cols="4" class="d-flex pa-0 pt-2 pr-1">
                    <v-row>
                        <v-col cols="4" class="pa-0 pr-1">
                            <v-card color="#FFA500" :class="classCard" >
                                <span class="uma-linha">SETUP</span>
                                <span class="">{{ appFabricaAtual.resourceRecursos.setup.length }}</span>
                            </v-card>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-card color="#1976D2" :class="classCard" >
                                <span class="uma-linha">MANUTENÇÃO</span>
                                <span class="">{{ appFabricaAtual.resourceRecursos.manutencao.length }}</span>
                            </v-card>
                        </v-col>
                        <v-col cols="4" class="pa-0 pl-1">
                            <v-card color="#757575" :class="classCard" >
                                <span class="uma-linha">DESLIGADA</span>
                                <span class="">{{ appFabricaAtual.resourceRecursos.offline.length }}</span>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-1 pt-1">
                <v-col cols="4" class="pa-0 pl-1">
                    <v-card style="width: 100%; height: calc(100vh - 146px); background-color: #616161; overflow-y: auto;">
                        <div v-for="machine in appFabricaAtual.resourceRecursos.producao" :key="machine.name">
                            <card_recursos_status  :name="machine.nome" :motivo_de_parada="machine.desc_motivo_parada"
                                :op="machine.ordem_de_producao" :nome_operador="machine.nome_operador" :state_sensor="machine.state_sensor"
                                :dt_inicio="machine.dt_inicio" :color="'#2E7D32'" :status="'producao'" :tipo="machine.tipo" />
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="4" class="pa-0 px-1">
                    <v-card style="width: 100%; height: calc(100vh - 146px); background-color: #616161; overflow-y: auto;">
                        <div style="width: 100%;" v-for="machine in appFabricaAtual.resourceRecursos.parada" :key="machine.name">
                            <card_recursos_status  :name="machine.nome" :motivo_de_parada="machine.desc_motivo_parada"
                                :op="machine.ordem_de_producao" :nome_operador="machine.nome_operador" :state_sensor="machine.state_sensor"
                                :dt_inicio="machine.dt_inicio" :color="'#D50000'" :status="'parada'" :tipo="machine.tipo" />
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="4" class="pa-0 pr-1">
                    <v-card style="width: 100%;  height: calc(100vh - 146px);  background-color: #616161; overflow-y: auto;">
                        <div style="width: 100%;" v-for="machine in appFabricaAtual.resourceRecursos.setup" :key="machine.name">
                            <card_recursos_status :name="machine.nome" :nome_operador="machine.nome_operador"
                                :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                :dt_inicio="machine.dt_inicio" :color="'#FFA500'" :status="'parada'" :tipo="machine.tipo" />
                        </div>
                        <div style="width: 100%;" v-for="machine in appFabricaAtual.resourceRecursos.manutencao" :key="machine.name">
                            <card_recursos_status  :name="machine.nome" :nome_operador="machine.nome_operador"
                                :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                :dt_inicio="machine.dt_inicio" :color="'#1976D2'" :status="'parada'" :tipo="machine.tipo" :observacao="machine.observacao"/>
                        </div>
                        <div style="width: 100%;" v-for="machine in appFabricaAtual.resourceRecursos.offline" :key="machine.name">
                            <card_recursos_status  :name="machine.nome" :nome_operador="machine.nome_operador"
                                :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                :dt_inicio="machine.dt_inicio" :color="'#757575'" :status="'offline'" :tipo="machine.tipo" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';
import { useAppMaquinas } from '../../../stores/appMaquinas';
import { useAppStore } from '../../../stores/app';
import { CalculaTempoMaquina } from '../../utils/funcoes';
import card_recursos_status from './cards/cards_recursos_status.vue';
import toolbarCharts from '../charts/toolbar/index.vue'
import { useAppFabricaAtual } from '../../../stores/appFabricaAtual';

export default {
    name: "charts_recursos_por_status",
    components: {
        card_recursos_status,
        toolbarCharts,
    },
    props: { apontamento: Object },
    data() {
        return {
            dialog: true,
            producao: [],
            parada: [],
            setup_manutencao_offline: [],
            timer: null,
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            currentMachine: null,
            classCard: 'text-start text-truncate d-flex justify-space-between rounded px-1 text-caption text-lg-h6 text-md-subtitle-1 font-weight-bold'
        }

    },

    computed: {
        appFabricaAtual() {
            return useAppFabricaAtual();
        },
        appCharts() {
            return useAppCharts();
        },
        appStore() {
            return useAppStore().set_apontamento_realtime()
        },
        resourceMaquinas() {
            return useAppMaquinas().resourceMaquinas;
        },    
    },
    watch: {
     
    },
     created (){
        frappe.realtime.on('apontamento_app', (data) => {
            useAppFabricaAtual().listar_recursos_abertos().then(async ()=>{
                const delay = (ms) => {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
            await delay(1000);
            useAppFabricaAtual().listar_recursos_abertos()
        })})

       frappe.realtime.on('recurso', (data) => {
        useAppFabricaAtual().listar_recursos_abertos().then(async ()=>{
            const delay = (ms) => {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
            await delay(1000);
            useAppFabricaAtual().listar_recursos_abertos()
        })})
    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    break;
                case 'shift':
                    break;
                case 'group':
                    break;
            };
        },
        tempoApontamento(dt_inicio) {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(dt_inicio)
            ).tempo
        },
    }
};
</script>

<style scoped>
.v-row{
    margin: 0 !important
}
.uma-linha {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%; 
    }
</style>
