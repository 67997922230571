<template>
    <div style="background-color: #dce0e6; height: 100% !important;">
        <v-row class="ma-2 mx-0">
            <v-col cols="12" md="6" class="pa-1">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0"> 
                        {{ display == 'sm' || display == 'lg' || display == 'xl' || display == 'xxl' ? 'Tempo de Disponibilidade das máquinas por Data e Turno' : 'Tempo de Disp. das máquinas por Data e Turno' }}
                    </v-card-text>
                    <div  :style="  display == 'xl' || display == 'xxl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;' ">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridMaq" :allowFiltering='true' :allowSorting='true'>
                            <e-columns>
                                <e-column field='data' headerText='Data' width='150'></e-column>
                                <e-column field='turno' headerText='Turno' width='150'></e-column>
                                <e-column field='hrs_disp' headerText='Horas Disponíveis' width='150'></e-column>
                                <e-column field='hrs_prod' headerText='Horas Produtivas' width='150'></e-column>
                                <e-column field='indice_disp' headerText='Índice Disp.' width='100'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1 px-0">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0">
                        {{ display == 'sm' || display == 'lg' || display == 'xl' || display == 'xxl' ? 'Detalhamento de Disponibilidade' : 'Detalhamento de Disp.' }}
                    </v-card-text>
                    <div :style="  display == 'xl' || display == 'xxl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;'">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridDetalhe" :allowFiltering='true'
                            :allowSorting='true'>
                            <e-columns>
                                <e-column field='nameApont' headerText='Status' width='150'></e-column>
                                <e-column field='total_hr_formated' headerText='Duração' width='150'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0">Disponibilidade Geral</v-card-text>
                    <div :style="  display == 'xl' || display == 'xxl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;'">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridGeral" :allowFiltering='true'
                            :allowSorting='true'>
                            <e-columns>
                                <e-column field='hrs_totais' headerText='Hrs Disp.' width='150'></e-column>
                                <e-column field='hrs_totais_prod' headerText='Hrs Prod.' width='150'></e-column>
                                <e-column field='indice_disp' headerText='Índice de Disp.' width='150'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'; 
import { useDisplay } from 'vuetify'

export default {
    name: "tabela_disponibilidade_resumida",
    data() {
        return{
            display: useDisplay().name,
        }
    },
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },
    props: {
        gridDetalhe: Object,
        gridGeral: Object,
        gridMaq: Object
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        dataGridDetalhe() {
            return this.gridDetalhe
        },
        dataGridGeral(){
            return this.gridGeral
        },
        dataGridMaq() {
            return this.gridMaq
        }

    }
}
</script>
