import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#dce0e6","height":"100% !important"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "ma-2 mx-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "6",
          class: "pa-1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.display == 'sm' || $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl' ? 'Tempo de Disponibilidade das máquinas por Data e Turno' : 'Tempo de Disp. das máquinas por Data e Turno'), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle(  $data.display == 'xl' || $data.display == 'xxl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;' )
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridMaq,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          field: "data",
                          headerText: "Data",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "turno",
                          headerText: "Turno",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "hrs_disp",
                          headerText: "Horas Disponíveis",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "hrs_prod",
                          headerText: "Horas Produtivas",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "indice_disp",
                          headerText: "Índice Disp.",
                          width: "100"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "pa-1 px-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.display == 'sm' || $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl' ? 'Detalhamento de Disponibilidade' : 'Detalhamento de Disp.'), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle(  $data.display == 'xl' || $data.display == 'xxl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;')
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridDetalhe,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          field: "nameApont",
                          headerText: "Status",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "total_hr_formated",
                          headerText: "Duração",
                          width: "150"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "pa-1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode("Disponibilidade Geral")
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle(  $data.display == 'xl' || $data.display == 'xxl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 38px);' : 'height: 210px;')
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridGeral,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          field: "hrs_totais",
                          headerText: "Hrs Disp.",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "hrs_totais_prod",
                          headerText: "Hrs Prod.",
                          width: "150"
                        }),
                        _createVNode(_component_e_column, {
                          field: "indice_disp",
                          headerText: "Índice de Disp.",
                          width: "150"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}