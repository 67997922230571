import { Mp, StatusFabrica } from "./../app/common/interfaceApp";
import { useAppMaquinas } from "./appMaquinas";
import { defineStore } from "pinia";

declare let frappe: any;

export const useAppCharts = defineStore({
  id: "appCharts_iot",
  state: (): {
    showModal: Boolean;
    resourceApont: Mp[];
    resourceDataDisp: [];
    resourceDesempenho: [];
    showModalMp: Boolean;
    producao: Object;
    parada: Object;
    setup_manutencao_offline: Object;
    open_disponibilidade_resumida: Boolean;
    resourceStatusFabrica: Object;
    showGabriel: Boolean;
    showRebeka: Boolean;
    show_disponibilidade_resumida: boolean;
    show_motivo_de_parada: boolean;
    show_desempenho_da_empresa: boolean;
    shiftData: Object;
    resourceDisponibilidadeTurno : any
    resourceParetoDeParadas : any;
  } => ({
    showModal: false,
    resourceApont: [],
    resourceDataDisp: [],
    resourceDesempenho: [],
    showModalMp: false,
    producao: [],
    parada: [],
    setup_manutencao_offline: [],
    open_disponibilidade_resumida: false,
    resourceStatusFabrica: {
      trabalhando: [],
      parada: [],
      setup: [],
      manutencao: [],
      offline: [],
      currentMachine: null,
      tempExecucao: '00:00:00',
      timer: null,
    },
    showGabriel: false,
    showRebeka: false,
    show_disponibilidade_resumida: false,
    show_motivo_de_parada: false,
    show_desempenho_da_empresa: false,
    shiftData: [],
    resourceDisponibilidadeTurno : [],
    resourceParetoDeParadas : []
  }),

  actions: {
    async relatorio_de_motivos_de_parada(turno=null, dt_inicio=null, dt_fim=null, machines=null) {
      try {
        let filtros = {
          turno: turno,
          dt_inicio: dt_inicio,
          dt_fim: dt_fim,
          recurso: machines
        }
        const res = await frappe.call("nxlite.nx_producao.page.apontamento_iot.controller.relatorio_de_motivos_de_parada",
                                        {filtro: filtros
                                        })

        this.resourceParetoDeParadas = res.message          
        if (this.resourceParetoDeParadas.length > 0) {
          this.show_motivo_de_parada = true
        } else {
          this.show_motivo_de_parada = false
        }
      }
      catch(error : any) {
        console.error(error)
      }
  },
    async listar_turnos() {
      try {
        const { message } = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.listar_turnos"
        );
        if (Array.isArray(message)) {
          this.shiftData = message.map(turno => turno.name);
        } else {
          console.error("Resposta inesperada ao listar turnos:", message);
        }
      } catch (error) {
        console.error("Erro ao listar turnos:", error);
      }
    },
    async status_atual_fabrica() {
      const maquinasStore = useAppMaquinas();

      this.resourceStatusFabrica.offline = [];
      this.resourceStatusFabrica.trabalhando = [];
      this.resourceStatusFabrica.manutencao = [];
      this.resourceStatusFabrica.setup = [];
      this.resourceStatusFabrica.parada = [];

      maquinasStore.resourceMaquinas.forEach((recurso) => {
        const machine = {
          name: recurso.nome,
          op: recurso.ordem_de_producao,
          motivo_de_parada: recurso.apontamentos.find(
            (apt) => apt.status == "Aberto" && apt.tipo == "Parada"
          )
            ? recurso.apontamentos.find(
              (apt) => apt.status == "Aberto" && apt.tipo == "Parada"
            ).desc_motivo_parada
            : null,
          dt_inicio: recurso.apontamentos.find((apt) => apt.status == "Aberto")
            ? recurso.apontamentos.find((apt) => apt.status == "Aberto")
              .dt_inicio
            : null,
          nome_operador: recurso.apontamentos.find((apt) => apt.status == "Aberto")
            ? recurso.apontamentos.find((apt) => apt.status == "Aberto")
              .nome_operador
            : null,
          state_sensor: recurso.state_sensor,
          tipo: ""
        };

        const apontamento_aberto = {
          manutencao: null,
          setup: null,
        };

        recurso.apontamentos
          .filter((apontamento) => apontamento.status === "Aberto")
          .forEach((apontamento) => {
            apontamento_aberto.manutencao = apontamento.manutencao;
            apontamento_aberto.setup = apontamento.setup;
            machine.op = apontamento.ordem_de_producao;
            machine.motivo_de_parada = apontamento.desc_motivo_parada;
            machine.dt_inicio = apontamento.dt_inicio;
            machine.tipo = apontamento.tipo;
          });

        switch (machine.tipo) {
          case "Produção":
            this.resourceStatusFabrica.trabalhando.push(machine);
            break;
          case "Parada":
            if (apontamento_aberto.manutencao == 1) {
              this.resourceStatusFabrica.manutencao.push(machine);
            }

            if (apontamento_aberto.setup == 1) {
              this.resourceStatusFabrica.setup.push(machine);
            }

            if (apontamento_aberto.manutencao == 0 && apontamento_aberto.setup == 0) {
              this.resourceStatusFabrica.parada.push(machine);
            }
            break;
          default:
            this.resourceStatusFabrica.offline.push(machine);
            break;
        }

        if (machine.dt_inicio && this.currentMachine !== machine.name) {
          this.currentMachine = machine.name;
        }
      });
    },

    async get_all_apontamentos_abertos() {
      try {
        this.producao = [];
        this.parada = [];
        this.setup_manutencao_offline = [];
        const { message } = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_apontamentos_abertos"
        );

        this.producao = message.producao;
        this.parada = message.parada;
        this.setup_manutencao_offline = message.setup_manutencao_offline;
      } catch (err) {
        console.log("error do apontamento aberto", err);
      }
    },

    update_apontamento_realtime(apt) {
      const apontamento = apt.apontamento;
      if (apontamento.tipo == "Produção") {
        const foundIndex = this.producao.findIndex(
          (a) => a.name == apontamento.name
        );
        if (apontamento.status == "Aberto") {
          if (foundIndex == -1) {
            let new_producao = this.producao;
            new_producao.push(apontamento);
            this.producao = new_producao;
            return;
          } else {
            let new_producao = this.producao;
            new_producao[foundIndex] = apontamento;
            this.producao = new_producao;
            return;
          }
        }
        if (apontamento.status == "Fechado") {
          if (foundIndex != -1) {
            this.producao = this.producao.splice(foundIndex, 1);
          }
        }
      }
      if (apontamento.tipo == "Parada") {
        if (
          !apontamento.setup &&
          apontamento.status_sensor != "OFFLINE" &&
          !apontamento.manutencao
        ) {
          const foundIndex = this.parada.findIndex(
            (a) => a.name == apontamento.name
          );
          if (apontamento.status == "Aberto") {
            if (foundIndex == -1) {
              let new_parada = this.parada;
              new_parada.push(apontamento);
              this.parada = new_parada;
              return;
            } else {
              let new_parada = this.parada;
              new_parada[foundIndex] = apontamento;
              this.parada = new_parada;
              return;
            }
          }
          if (apontamento.status == "Fechado") {
            if (foundIndex != -1) {
              this.parada = this.parada.splice(foundIndex, 1);
            }
          }
        } else {
          //QUALQUER OUTRA COISA
          const foundIndex = this.setup_manutencao_offline.findIndex(
            (a) => a.name == apontamento.name
          );
          if (apontamento.status == "Aberto") {
            if (foundIndex == -1) {
              let new_setup_manutencao_offline = this.setup_manutencao_offline;
              new_setup_manutencao_offline.push(apontamento);
              this.setup_manutencao_offline = new_setup_manutencao_offline;
              return;
            } else {
              let new_setup_manutencao_offline = this.setup_manutencao_offline;
              new_setup_manutencao_offline[foundIndex] = apontamento;
              this.setup_manutencao_offline = new_setup_manutencao_offline;
              return;
            }
          }
          if (apontamento.status == "Fechado") {
            if (foundIndex != -1) {
              this.setup_manutencao_offline =
                this.setup_manutencao_offline.splice(foundIndex, 1);
            }
          }
        }
      }
    },

    async disp_resumida(maquina=null, turno=null, dt_inicio=null, dt_fim=null) {

      let filtros = {
        recurso: maquina,
        turno: turno,
        dt_inicio: dt_inicio,
        dt_fim: dt_fim
      }

      const res = await frappe.call(
        "nxlite.nx_producao.page.apontamento_iot.controller.get_infos_disp_resumida", 
        {filtro: filtros}
      );

      this.resourceDataDisp = res.message

      if(this.resourceDataDisp.grafico_grid.length > 0) {
        this.show_disponibilidade_resumida = true
      } else {
        this.show_disponibilidade_resumida = false
      }
    },


    async desempenho_empresa(maquina=null, turno=null, dt_inicio=null, dt_fim=null, type=null, groupBy=null) {
      
      let filtros = {
        recurso: maquina,
        turno: turno,
        dt_inicio: dt_inicio,
        dt_fim: dt_fim,
        type: type,
        groupBy: groupBy
      }

      const res = await frappe.call(
        "nxlite.nx_producao.page.apontamento_iot.controller.get_infos_desempenho",
        {filtro: filtros}
      );

      this.resourceDesempenho = res.message


      // if(this.resourceDataDisp.grafico_grid.length > 0) {
      //   this.show_disponibilidade_resumida = true
      // } else {
      //   this.show_disponibilidade_resumida = false
      // }
    },


    async listar_apontamentos_chart() {
      this.resourceApont = [];

      const res = await frappe.call({
        method: "nxlite.nx_producao.page.apontamento_iot.controller.get_infos_disp_resumida", 
        args: {filter: "andre"}
      });

      res.message.todos_aponta.forEach((apontamento: any) => {
        this.resourceApont.push({
          centro_de_custo: apontamento.centro_custo
            ? apontamento.centro_custo
            : null,
          desc_motivo_parada: apontamento.desc_motivo_parada,
          desc_recurso: apontamento.desc_recurso,
          dt_fim: apontamento.dt_fim,
          dt_inicio: apontamento.dt_inicio,
          manutencao: apontamento.manutencao,
          motivo_de_parada: apontamento.motivo_de_parada,
          name: apontamento.name,
          nome_operador: apontamento.nome_operador,
          operador: apontamento.operador,
          setup: apontamento.setup,
          status: apontamento.status,
          status_sensor: apontamento.status_sensor,
          tipo_turno: apontamento.tipo_turno,
          total_hr: apontamento.total_hr,
          turno: apontamento.turno,
          tipo: apontamento.tipo,
          total_hr_disp: res.message.total_hrs,
          total_hr_prod: res.message.total_hrs_prod,
          indiceDisp: res.message.indiceDisp
        });
      });

    },
  } 
});